<template>
  <div class="container">
    <div class="form-container">
      <form @submit.prevent="sendEmail">
        <h3 class="color-primary text-center">ลืมรหัสผ่าน</h3>
        <label class="color-primary">อีเมล</label>
        <input
          type="email"
          class="w-100"
          placeholder="อีเมล"
          v-model="email"
          required
        />
        <button class="primary w-100">
          <p class="text-big">ส่งอีเมล</p>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '../api/faAPI';;
import baseUrl from "../util/backend";
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async sendEmail() {
      this.$store.dispatch("setLoading", true);
      try {
        const res = await axios.post(`${baseUrl}/resetpassword`, {
          email: this.email,
        });
        console.log(res);
        alert("ส่งอีเมลเรียบร้อยแล้ว");
        this.$router.replace({ name: "Home" });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.$store.dispatch("setLoading", false);
    },
  },
};
</script>

<style scoped>
.form-container {
  max-width: 370px;
  margin: 110px auto;
}

.form-container input {
  border: 1px solid #0430ad;
  outline: none;
  padding: 10px;
}

button.primary {
  color: #fff;
  background-color: #0430ad;
  padding: 10px;
  margin: 10px 0;
}
</style>
